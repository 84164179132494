import React, { useRef } from 'react';
import Back from "../common/back/Back";
import "./contact.css";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3567.148927184916!2d75.1546002!3d27.6226042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396cbb3f7fb7c08f%3A0x3a9f89c9e5e2b633!2sDoctors%20academy!5e0!3m2!1sen!2sin!4v1652535615693!5m2!1sen!2sin';
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0bfz0zd', 'template_rxbvaln', form.current, 'HPFBR-V5X0I-i_b_O')
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset(); // Clear the form fields
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <>
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe 
              src={map} 
              title="Location Map" 
              style={{ border: 0 }} 
              allowFullScreen="" 
              loading="lazy">
            </iframe>
          </div>
          <div className='right row'>
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>PCMB Easy Learning Piprali Road, Sikar Rajasthan</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p>pcmbeasy@gmail.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p>+91 9828050031</p>
              </div>
            </div>

            <form ref={form} onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' name="from_name" placeholder='Name' required />
                <input type='email' name="email" placeholder='Email' required />
              </div>
              <input type='text' name="from_sub" placeholder='Subject' required />
              <input type='text' name="from_number" placeholder='phone number' required />
              <textarea name="message" cols='30' rows='10' placeholder="Create a message here..." required></textarea>
              <button type="submit" className='primary-btn'>SEND MESSAGE</button>
            </form>

          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
