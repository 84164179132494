import React from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
// import { homeAbout } from "../../dummydata"
import Awrapper from "./Awrapper"

const AboutCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>
            <img src='./images/P.C. Sir.png' alt='' />
          </div>
          <div className='right row'>
            <Heading  title='Founder & Director' />
            <div className='items'>
              {/* subtitle='LEARN ANYTHING' */}
              {/* {homeAbout.map((val) => { */}
                {/* return ( */}
                  <div className='item flexSB'>
                    {/* <div className='img'>
                      <img src={val.cover} alt='' />
                    </div> */}
                    <div className='text'>
                      {/* <h2>{val.title}</h2> */}
                      
                      <p>P.C sir (Mr. Pradeep Choudhary) is director cum Sr. faculty of chemistry. He is chemistry faculty
                        with vast teaching <strong>experience of  15+ years.</strong> Before founding VCI, he has been  a  senior most <strong>chemistry faculty  at 
                        Bansal classes for 4 years</strong> and he was one  of  the <strong>Most famous chemistry faculty at Gurukripa Career Institute Sikar for last 5 years.</strong> Earlier he has 
                        worked as <strong>Director at Daswani Classes,Jodhpur</strong> and helpes more than <strong>300</strong>  pre-medical 
                        aspirants to achieve their goal to become a doctor. During experience of 13 years he has <strong>taught 
                        IIT-JEE chemistry</strong> to more than 6000 student and <strong>helped more than 700 students
                        getting into IITs.</strong>
                        He regularly inspires student to overcome their mental blocks towards chemistry and simplifies
                        the subject to  an extent that it often become the student's favorite subject. He intends to  make a difference by utilizing new teaching standards and
                        techniques. His experience, stature and the number of success stories are known to all and have attracted children from all parts of the
                        country.
                        <strong>"Education is the most powerful weapon which you can use to change the world"</strong>,it has always been his consciours effort to provide wholesome knowledge of chemistry. it is a scoring subject
                        always been his conscious effort to provide wholesome knowledge of Chemistry. It is  a scoring
                        subject and a systenatic approach to solving problems always helps a lot.
                      </p>
                      {/* <p>{val.desc}</p> */}
                    </div>
                  </div>
                {/* ) */}
              {/* })} */}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
