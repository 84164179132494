import React from "react";
import "./courses.css";
import { coursesCard } from "../../dummydata";

const CoursesCard = () => {
  return (
    <section className='coursesCard'>
      <div className='container grid2'>
        {coursesCard.map((val, index) => (
          <div className='items' key={index}>
            <div className='content flex'>
              <div className='left'>
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
              </div>
              <div className='text'>
                <h1>{val.coursesName}</h1>
                <div className='rate'>
                  {[...Array(5)].map((_, i) => (
                    <i key={i} className='fa fa-star'></i>
                  ))}
                  <label htmlFor=''>{val.rating} (5.0)</label>
                </div>
                <div className='details'>
                  {val.courTeacher.map((details, index) => (
                    <React.Fragment key={index}>
                      <div className='box'>
                        <div className='dimg'>
                          <img src={details.dcover} alt='' />
                        </div>
                        <div className='para'>
                          <h4>{details.name}</h4>
                        </div>
                      </div>
                      <span>{details.totalTime}</span>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className='price'>
              <h3>{val.priceAll}</h3>
            </div>
            <a
              href='https://play.google.com/store/apps/details?id=co.alexis.dwhto&pli=1'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='outline-btn'>Download App</button>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CoursesCard;
