import React from "react"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <h1>PCMB Easy Learning</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
          </div>

          <div className='social'>
            <a href="https://www.facebook.com/profile.php?id=61559272421212"><i className='fab fa-facebook-f icon'></i></a>
            <a href="https://www.instagram.com/pcmbeasy?igsh=OHhyb2hwYjNpcDRm"><i className='fab fa-instagram icon'></i></a>
            {/* <a href=""><i className='fab fa-twitter icon'></i></a> */}
            <a href="https://youtube.com/@pcmbeasylearning-kn7gz?si=H2bPtes0EUvxEFRI"><i className='fab fa-youtube icon'></i></a>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
