import React from "react"
// import { blog } from "../../../dummydata"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <h1>PCMB</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>"Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful."</p>

            <a href="hhttps://www.facebook.com/profile.php?id=61559272421212"><i className='fab fa-facebook-f icon'></i></a>
            <a href="https://www.instagram.com/pcmbeasy?igsh=OHhyb2hwYjNpcDRm"><i className='fab fa-instagram icon'></i></a>
          </div>
          <div className='box link'>
          <ul class="footer-images">
                <li>
                    <a href="https://play.google.com/store/apps/details?id=co.alexis.dwhto&pli=1" target="_blank" rel="noreferrer" class="footer-link">
                        <img src="./images/playstore.png" alt="Play Store" class="footer-image" />
                        <span class="footer-text">Download App </span>
                    </a>
                </li>
          </ul>
          </div>
         
          <div className='box'>
          
          </div>
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                PCMB Easy Learning Piprali Road ,Sikar Rajasthan
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                +91 9828050031
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                pcmbeasy@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved | This template is made with <i className='fa fa-heart'></i> by Web Elite Digital Marketing
        </p>
      </div>
    </>
  )
}

export default Footer
