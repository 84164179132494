// export const homeAbout = [{
//         id: 1,
//         cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
//         title: "Online Courses",
//         desc: "Education is the most powerful weapon which you can use to change the world.",
//     },
//     {
//         id: 1,
//         cover: "https://img.icons8.com/ios/80/000000/diploma.png",
//         title: "Earn A Certificates",
//         desc: "Education is the most powerful weapon which you can use to change the world.",
//     },
//     {
//         id: 1,
//         cover: "https://img.icons8.com/ios/80/000000/athlete.png",
//         title: "Learn with Expert",
//         desc: "Education is the most powerful weapon which you can use to change the world.",
//     },
// ]
export const awrapper = [{
        cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
        data: "3,000",
        title: "SUCCESS STORIES",
    },

    {
        cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
        data: "320",
        title: "TRUSTED TUTORS",
    },
    {
        cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
        data: "1,000",
        title: "SCHEDULES",
    },
    {
        cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
        data: "587",
        title: "COURSES",
    },
]
export const coursesCard = [{
        id: 1,
        cover: "../images/courses/c1.png",
        coursesName: "Introducing to pre-foundation NTSE /OLYMPIAD/NEET/JEE",
        courTeacher: [{
            dcover: "./images/PCsir.webp",
            name: "by Pradeep Choudhery",
            // totalTime: "",
        }, ],
        priceAll: "EX - 22 year",
        // pricePer: "$15 per month",
    },
    {
        id: 2,
        cover: "../images/courses/c2.png",
        coursesName: "Introducing to XI & XII IIT-JEE Foundation ",
        courTeacher: [{
            dcover: "./images/t11.webp",
            name: "by Mohan sir",
            // totalTime: "",
        }, ],
        priceAll: "EX - 6  year",
        // pricePer: "$25 per month",
    },
    {
        id: 3,
        cover: "../images/courses/c3.png",
        coursesName: "Introducing to XI & XII NEET Foundation",
        courTeacher: [{
            dcover: "./images/t4.webp",
            name: "by Shankar Sir",
            // totalTime: " ",
        }, ],
        priceAll: "Ex - 4 year",
        // pricePer: "$5 per month",
    },

]
export const online = [{
        cover: "./images/courses/online/graduation.png",
        hoverCover: "./images/courses/online/graduation.png",
        courseName: "VIII Pre-foundation(NTSE,IIT,OLYMPIAC,JEE)",
        course: "https://youtube.com/@pcmbeasy?si=scXNieTMFriZRY3o",
    },
    {
        cover: "./images/courses/online/digital-book.png",
        hoverCover: "./images/courses/online/digital-book.png",
        courseName: "IX Pre-foundation(NTSE,IIT,OLYMPIAC,JEE)",
        course: "https://youtube.com/@pcmbeasylearningxi?si=lYK6ZI5sMmJb-wSJ",
    },
    {
        cover: "./images/courses/online/distance-education.png",
        hoverCover: "./images/courses/online/distance-education.png",
        courseName: "X Pre-foundation(NTSE,IIT,OLYMPIAC,JEE)",
        course: "https://youtube.com/@pcmbeasylearningx?si=P-v5fcaY94gWfOrW",
    },
    {
        cover: "./images/courses/online/ebook.png",
        hoverCover: "./images/courses/online/ebook.png",
        courseName: "XI Foundation IIT-JEE",
        course: "https://youtube.com/@pcmbeasyiit?si=alSoCA9I-p06FUlQ",
    },
    {
        cover: "./images/courses/online/computer.png",
        hoverCover: "./images/courses/online/computer.png",
        courseName: "XII Foundation IIT-JEE",
        course: "https://youtube.com/@pcmbeasyiit?si=alSoCA9I-p06FUlQ",
    },
    {
        cover: "./images/courses/online/o1.png",
        hoverCover: "./images/courses/online/o1.1.png",
        courseName: "XIII Dropper IIT-JEE",
        course: "https://youtube.com/@pcmbeasyiit?si=alSoCA9I-p06FUlQ",
    },
    {
        cover: "./images/courses/online/o7.png",
        hoverCover: "./images/courses/online/o7.1.png",
        courseName: "For xi foundation NEET",
        course: "https://youtube.com/@pcmbeasylearningxxi?si=BcnUVG7odn1-zpia",
    },
    {
        cover: "./images/courses/online/o8.png",
        hoverCover: "./images/courses/online/o8.1.png",
        courseName: "For xii foundation NEET",
        course: "https://youtube.com/@pcmbeasylearninigxii?si=X3YLqmbEV58qFqOu",
    },
    {
        cover: "./images/courses/online/o9.png",
        hoverCover: "./images/courses/online/o9.1.png",
        courseName: "XIII Dropper NEET",
        course: "https://youtube.com/@pcmbeasylearning-kn7gz?si=BtYsCgy8LBrLPvKT",
    },
    // {
    //     cover: "./images/courses/online/o10.png",
    //     hoverCover: "./images/courses/online/o10.1.png",
    //     courseName: "Pre-Foundaion",
    //     course: "120 Courses",
    // },
    // {
    //     cover: "./images/courses/online/o11.png",
    //     hoverCover: "./images/courses/online/o11.1.png",
    //     courseName: "Pre-Foundaion",
    //     course: "17 Courses",
    // },
    // {
    //     cover: "./images/courses/online/o12.png",
    //     hoverCover: "./images/courses/online/o12.1.png",
    //     courseName: "Pre-Foundaion",
    //     course: "17 Courses",
    // },
]
export const team = [{
        cover: "./images/team/PCsir.webp",
        name: "Pradeep Choudhary",
        work: "PHY. & CHEM.",
        Exp: "Exp: 22+ yrs"
    },
    {
        cover: "./images/team/t11.webp",
        name: "Mohan saini ",
        work: "PHYSICS",
        Exp: "Exp: 6+ yrs"
    },

    {
        cover: "./images/team/t4.webp",
        name: "Shankar Sir",
        work: "BOTANY",
        Exp: "Exp: 4+ yrs"
    },
    {
        cover: "./images/team/t5.webp",
        name: "Pankaj Sir",
        work: "CHEMISTRY",
        Exp: "Exp: 4+ yrs"
    },
    {
        cover: "./images/team/t6.webp",
        name: "Narendra Sir",
        work: "CHEMISTRY",
        Exp: "Exp: 3+ yrs"
    },

]
export const price = [{
        name: "BASIC PLAN",
        price: "49K",
        desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
        name: "BEGINNER PLAN",
        price: "79K",
        desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
        name: "PREMIUM PLAN",
        price: "109k",
        desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
        name: "ULTIMATE PLAN",
        price: "149K",
        desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
]
export const faq = [{
        title: "How to Enroll This Online Courses?",
        desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
        title: "Where It is hidden by default, until the collapse?",
        desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
        title: "How It is hidden by default, until the collapse?",
        desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
        title: "How to Enroll This Online Courses?",
        desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
        title: "Where It is hidden by default, until the collapse?",
        desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
        title: "How It is hidden by default, until the collapse?",
        desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
]

export const testimonal = [{
        id: 1,
        name: "VISHAL KUMAR",
        post: "MMBS STUDENT",
        desc: "The only way to do great work is to love what you do",
        cover: "./images/testo/t1.png",
    },
    {
        id: 2,
        name: "VIKASH",
        post: "MMBS STUDENT",
        desc: "The future belongs to those who believe in the beauty of their dreams.",
        cover: "./images/testo/t2.webp",
    },
    {
        id: 3,
        name: "RAHUL SHARMA",
        post: "MMBS STUDENT",
        desc: "Success is not final, failure is not fatal: It is the courage to continue that counts.",
        cover: "./images/testo/t3.png",
    },
]